import { useEffect, useState } from 'react';
import './../../../App.css';
import { useTranslation } from 'react-i18next'
import i18n from "i18next";


export default function Introduction(){
    const { t }  = useTranslation(['intro']) //i18n


    return(
        <>
            <div className='detail-page'>
                <div className='detail-page__banner-con'>
                    <div>COMPANY</div>
                    <img src='/images/menu_banner.jpg' decoding="async"/>
                </div>

                <div className='detail-page__con'>
                    <div className='detail-page__txt-line'></div>
                    <div className='detail-page__txt-con'>
                        <div className='detail-page__txt-title'>{t('introduction:header-title').split("\n").map((line)=>(<div>{line}</div>))}</div>
                        <div className='detail-page__txt-sub'>{t('introduction:header-sub').split("\n").map((line)=>(<div>{line}</div>))}</div>
                    </div>

                    <ul className='introduction__content-con'>
                        <li className='introduction__content-item'>
                            <img src='/images/contact.jpg' decoding="async"/>
                            <div className='introduction__content-txt-con introduction__right'>
                                <div className={`introduction__content-txt-title`}>
                                    {t('introduction:content-txt-title01').split("\n").map((line)=>(<div>{line}</div>))}</div>
                                <div className={`introduction__content-txt-sub`}>
                                    {t('introduction:content-txt-sub01').split("\n").map((line)=>(<div>{line}</div>))}
                                </div>
                            </div>
                        </li>
                        <li className='introduction__content-item'>
                        <img className='introduction__img-mo' src='/images/brand.jpg' decoding="async"/>
                        <div className='introduction__content-txt-con introduction__left'>
                            <div className={`introduction__content-txt-title`}>
                                {t('introduction:content-txt-title02').split("\n").map((line)=>(<div>{line}</div>))}</div>
                            <div className={`introduction__content-txt-sub`}>
                                {t('introduction:content-txt-sub02').split("\n").map((line)=>(<div>{line}</div>))}
                            </div>
                            </div>
                            <img className='introduction__img-pc' src='/images/brand.jpg' decoding="async"/>
                        </li>
                        <li className='introduction__content-item'>
                            <img src='/images/menu05.jpg' decoding="async"/>
                            <div className='introduction__content-txt-con introduction__right'>
                            <div className={`introduction__content-txt-title`}>
                                {t('introduction:content-txt-title03').split("\n").map((line)=>(<div>{line}</div>))}</div>
                            <div className={`introduction__content-txt-sub`}>
                                {t('introduction:content-txt-sub03').split("\n").map((line)=>(<div>{line}</div>))}
                            </div>                            </div>
                        </li>
                        <li className='introduction__content-item'>
                            <img className='introduction__img-mo' src='/images/introduction.jpg' decoding="async"/>
                            <div className='introduction__content-txt-con introduction__left'>
                                <div className={`introduction__content-txt-title`}>
                                    {t('introduction:content-txt-title04').split("\n").map((line)=>(<div>{line}</div>))}</div>
                                <div className={`introduction__content-txt-sub`}>
                                    {t('introduction:content-txt-sub04').split("\n").map((line)=>(<div>{line}</div>))}
                                </div>
                            </div>
                            <img className='introduction__img-pc' src='/images/introduction.jpg' decoding="async"/>
                        </li>
                    </ul>

                    {/* <div className='detail-page__txt-line'></div>
                    <div className='detail-page__txt-con'>
                        <div className='detail-page__txt-title'>{t('introduction:years-header').split("\n").map((line)=>(<div>{line}</div>))}</div>
                    </div>

                    <div className='detail-page__history-con'>
                        <div className='detail-page__history-item'>
                            <div className='detail-page__history-year'>2022</div>
                            <div className='detail-page__history-content'>
                                - 내용을 기입해 주세요.<br/>
                                - 내용을 기입해 주세요.<br/>
                                - 내용을 기입해 주세요.<br/>
                            </div>
                        </div>
                        <div className='detail-page__history-item'>
                            <div className='detail-page__history-year'>2020</div>
                            <div className='detail-page__history-content'>
                                - 내용을 기입해 주세요.<br/>
                                - 내용을 기입해 주세요.<br/>
                                - 내용을 기입해 주세요.<br/>
                            </div>
                        </div>
                        <div className='detail-page__history-item'>
                            <div className='detail-page__history-year'>2018</div>
                            <div className='detail-page__history-content'>
                                - 내용을 기입해 주세요.<br/>
                                - 내용을 기입해 주세요.<br/>
                                - 내용을 기입해 주세요.<br/>
                            </div>
                        </div>
                    </div> */}
                    
                </div>
            </div>
        </>
    )
}