
const serviceData = [
    ['plan.svg',
    '안전계획 수립',
    '해상풍력발전단지 건설과 운영 중 발생할 수 있는 위험을 식별하고 이해함으로써 잠재적인 위험 완화를 위한 전략과 안전계획을 수립할 수 있습니다.'],
    ['monitoring.svg',
    '모니터링 시스템',
    '원격 모니터링 및 감시 기술을 활용한  상황실을 운영하며 on-site 대응력을 높이기 위해 순시선을 활용하여 통합관제시스템을 완성합니다.'],
    ['safety.svg',
    '해상교통안전진단 이행',
    '해상풍력발전단지 인허가과정에서 수행된 해사안전법에 따른 해상교통안전진단 협의사항을 충실히 이행하고 보고합니다.'],
    ['alert01.svg',
    '비상대응 시스템',
    '해상풍력발전단지 건설과 운영 중 발생할 수 있는 응급상황의 영향을 최소화하려면 적절한 안전조치, 구조장비 및 훈련된 인력확보가 중요합니다.'],
    ['law.svg',
    '규제준수',
    '해양규제환경과 안전표준은 지속적으로 강화되고 있습니다. 국내규정과 국제안전표준을 준수함으로써 안전한 환경을 조성합니다.'],
    ['communication.svg',
    '의사소통',
    '풍력발전 사업자, 어민, 해양당국과 효과적인 의사소통 채널을 구축하여 잠재적인 위험정보를 공유하며 사고를 예방하고 사고 발생시 신속한 대응을 보장합니다.'],
    ['alert02.svg',
    '비상훈련',
    '해양공간에서 운영되는 프로젝트 특성에 맞게 비상상황에 적합한 훈련 및 프로그램을 개발하고 실질적인 대응훈련을 주기적으로 실시하여 사고 발생 시 신속한 대응을 보장합니다.'],
    ['weather.svg',
    '현장기상정보제공',
    '공사현장의 해양상황 및 기상정보를 제공함으로써 안전한 공사 진행과 자연재해에 신속히 대응할 수 있습니다.'],
    ['consulting.svg',
    '컨설팅',
    '해상풍력발전 단지 건설과 운영을 위한 WTIV, CTV, SOV 등 특수목적선박에 대한 용선컨설팅 서비스를 수행합니다.']
]

export default serviceData;