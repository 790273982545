import { useEffect } from 'react';
import './../App.css';

export default function Footer(){

    useEffect(() => {
        return () => {
        };
    }, [])

    return(
        <>
            <div className='footer'>
                <div className='footer__con'>
                    <div className='footer__logo'>SOO UNIVERSE</div>
                    <div className='footer__txt-con'>
                        <div className='footer__txt01'> 
                            상호 : 수유니버스 | 사업장소재지 : 서울시 성동구 상원1길 25 SOL 빌딩 4426호(성수동 1가) | 대표자명 : 기상도
                        <br/>
                            사업자등록번호 : 101-86-70517
                        <br/>
                            상담번호: 02-6952-0667
                        <br/>
                        </div>
                        <div className='footer__txt02'>
                            <span>©</span> SOO UNIVERSE. All Rights Reserved.
                        </div>
                    </div>
                    
                    <div className='footer__law-con'>
                        <a href={process.env.PUBLIC_URL + '/license'}>오픈소스 라이선스</a>
                    </div>
                </div>
            </div>
        </>
    )
}