import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import './../App.css';

import { useTranslation } from 'react-i18next'
import i18n from "i18next";


export default function Header(props){

    // i18n
    const { t }  = useTranslation(['page'])

    const onChangeLangKo = () => {
        i18n.changeLanguage('ko')
        localStorage.setItem('language', 'ko') // for maintaining language when changing page
    }
    const onChangeLangEn = () => {
        i18n.changeLanguage('en')
        localStorage.setItem('language', 'en') // for maintaining language when changing page
    }
    
    // 
    const [scrollPosition, setScrollPosition] = useState(null);
    const [clickHamburger, setClickHamburger] = useState(null);

    // 스크롤 위치 인식
    const updateScroll = () => {
        setScrollPosition(window.scrollY || document.documentElement.scrollTop);
    }

    // 무한 스크롤 방지하기 위해서 100ms마다 스크롤 위치 체크
    useEffect(()=>{
        const timer = setTimeout(window.addEventListener('scroll', updateScroll), 100)       
        return () => {
            clearTimeout(timer);
        };
    });

    // 햄버거 버튼 클릭시 메뉴 보이기
    const showList = () => {
        setClickHamburger(!clickHamburger);
    };

    const modal = () => {
        alert('해상풍력 안전관리에 관한 일반 문의 또는 미디어 관련 문의는 다음 양식을 작성해서 아래의 주소로 이메일을 보내주시기 바랍니다.\n수신 이메일 : jylee@soouniverse.com 바랍니다.\n\n이름\n성\n이메일 주소\n회사명/소속\n문의내용')
    }


    return (
        <>
            <header className={`header + ${scrollPosition < 50 ? "" : "header-changed"} + header-unclick`}>
                <div className='header__con'>
                    {/* 로고 */}
                    <ul className='header__logo-con'>
                        <li className='header__logo'><a href='/'>SOO UNIVERSE</a></li>
                        {/* <li className='header__logo'><a href='/'><img src='/img/logo_white.png' decoding="async"/></a></li> */}
                        <li className='header__hamburger'><a><img src={clickHamburger ? `/icons/btn_x.png` : `/icons/btn_hamburger.png`} onClick={showList}/></a></li>
                    </ul>
                    
                    {/* 메뉴 */}
                    <ul className='header__category-con'>
                        <li>
                            <a href='/'>{t('header:home').split("\n").map((line)=>(<div>{line}</div>))}</a>
                        </li>
                        <li>
                            <a href='/company'>{t('header:company').split("\n").map((line)=>(<div>{line}</div>))}</a>
                        </li>
                        <li>
                            <a href='/ourservice'>{t('header:service').split("\n").map((line)=>(<div>{line}</div>))}</a>
                        </li>
                        <li>
                            <a href='/gallery'>{t('header:gallery').split("\n").map((line)=>(<div>{line}</div>))}</a>
                        </li>
                        <li>
                            <a href='/news/list'>{t('header:news').split("\n").map((line)=>(<div>{line}</div>))}</a>
                        </li>
                        <li className='header__contact-btn'>
                            <a href='/' onClick={()=>{modal()}}>{t('header:contact').split("\n").map((line)=>(<div>{line}</div>))}</a>
                        </li>
                    </ul>
                    <div className='header__lang-btn'>
                        <div className='header__lang-btn-con'>
                            {localStorage.getItem('language') == 'en' ? 'ENG' : 'KOR'}<span>&#8897;</span>
                            <ul>
                                <li onClick={onChangeLangKo}>KOR</li>
                                <li onClick={onChangeLangEn}>ENG</li>
                            </ul>
                        </div>
                    </div>
                </div>
            </header>

            <div className={`header-mo ${clickHamburger ? 'left-0' : '' }`}>
                <div className='header__lang-btn'>
                    <div className='header__lang-btn-con'>
                        {localStorage.getItem('language') == 'en' ? 'ENG' : 'KOR'}<span>&#8897;</span>
                        <ul>
                            <li onClick={onChangeLangKo}>KOR</li>
                            <li onClick={onChangeLangEn}>ENG</li>
                        </ul>
                    </div>
                </div>
                <ul className='header-mo__category-con'>
                    <li>
                        <a href='/'>{t('header:home').split("\n").map((line)=>(<div>{line}</div>))}</a>
                    </li>
                    <li>
                        <a href='/company'>{t('header:company').split("\n").map((line)=>(<div>{line}</div>))}</a>
                    </li>
                    <li>
                        <a href='/ourservice'>{t('header:service').split("\n").map((line)=>(<div>{line}</div>))}</a>
                    </li>
                    <li>
                        <a href='/gallery'>{t('header:gallery').split("\n").map((line)=>(<div>{line}</div>))}</a>
                    </li>
                    <li>
                        <a href='/news/list'>{t('header:news').split("\n").map((line)=>(<div>{line}</div>))}</a>
                    </li>
                    <li>
                        <a href='/'  onClick={()=>{modal()}}>{t('header:contact').split("\n").map((line)=>(<div>{line}</div>))}</a>
                    </li>
                </ul>
            </div>

            <div className='bg-gray' style={{display: clickHamburger? 'block' : 'none'}} onClick={showList}></div>

            <div className='header-block'></div> {/* prevent for hiding other components */}
        </>
    )
}
