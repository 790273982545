import { useEffect, useState } from 'react';
import './../../../App.css';
import { useNavigate } from 'react-router-dom';
import data from '../../../data/boardData';
import boardData from '../../../data/boardData';

export default function BoardList(){

    // [state] pagination number
    const boardData = ([...data].reverse())
    const batch = 5; // 한 페이지당 글 개수
    const [boardFromNum, setBoardFromNum] = useState(0);
    const [boardToNum, setBoardToNum] = useState(batch); 

    // [event] pagination
    const totalPage = Math.ceil(boardData?.length / batch);
    const pageArr = [];
    for (let i = 0; i < totalPage; i++) {
        pageArr.push(i+1);
    }
    const [currentPage, setCurrentPage] = useState(1); // current page (default: 1)
    const clickPageNumber = (e) => {
        setCurrentPage(e);
        setBoardFromNum((batch*(e-1)));
        setBoardToNum((batch*(e-1)) + batch);
    }

    return(
        <>
            <div className='detail-page'>
                <div className='detail-page__banner-con'>
                    <div>NEWS</div>
                    <img src='/images/menu_banner.jpg' decoding="async"/>
                </div>

                <div className='detail-page__con'>
                    <div className='detail-page__txt-line'></div>
                    <div className='detail-page__txt-con'>
                        <div className='detail-page__txt-title'>뉴스 및 최신소식</div>
                    </div>

                    <table className='board__table'>
                            <thead className='board__thead'>
                                <tr>
                                    <th className='board__th01'>번호</th>
                                    <th className='board__th02'>제목</th>
                                    <th className='board__th03'>작성자</th>
                                </tr>
                        </thead>

                        {/* PC용 */}
                        <tbody className='board__tbody'>
                            {
                                boardData?.slice(boardFromNum,boardToNum).map((a, i) => {
                                    return <BoardItemPc a={a} i={i} preNumber = {batch * (currentPage - 1)} />
                                })
                            }
                        </tbody>
                    </table>

                    {/* 모바일용 */}
                    <div className='board__list-con'>
                        {
                            boardData?.slice(boardFromNum,boardToNum).map((a, i) => {
                                return <BoardItemMo a={a} i={i} preNumber = {batch * (currentPage - 1)} />
                            })
                        }
                    </div>

                    <div className='board__page-number'>
                        {pageArr?.map((a, i) => {
                            return(
                                <div
                                    style={{fontWeight: a == currentPage ? '600' :'300'}}
                                    onClick={() => {clickPageNumber(a)}}>
                                        {a}
                                </div>
                            )})}
                    </div>   
                </div>
            </div>
        </>
    )
}

function BoardItemPc(props){
    const navigate = useNavigate();
    const number = boardData.length - props.i
    const preNumber = props.preNumber

    return (
        <>
            <tr>
                <td>{number - preNumber}</td>
                <td style={{cursor:'pointer'}} onClick={ () => {navigate('/news/detail/' + (number - preNumber))}}>
                    {props.a.title}&nbsp;&nbsp;</td>
                <td>{props.a.writer}</td>
            </tr>
        </>
    )
}

function BoardItemMo(props){
    const navigate = useNavigate();
    const number = boardData.length - props.i
    const preNumber = props.preNumber

    return (
        <>
            <ul className='board__list-item'>
                <li className='board__list01' onClick={ () => {navigate('/news/detail/' + (number - preNumber))}}>
                    {props.a.title}&nbsp;&nbsp;
                </li>
                <li className='board__list02'>
                번호 {number - preNumber}&nbsp;&nbsp;|&nbsp;&nbsp;작성자 {props.a.writer}
                </li>
            </ul>
        </>
    )
}