
const boardData = [
    {
        id: 0,
        writer: '운영자',
        title: '수 유니버스에 오신걸 환영합니다.',
        images: ['0_1.jpg'],
        content: 
`안녕하세요.

수유니버스는 해상 풍력 프로젝트의 운영 효율성, 안전 및 규제 준수를 보장하기 위해 맞춤화된 안전관리 서비스를 제공합니다.
저희 홈페이지에 오신 걸 환영합니다.`
    },
    {
        id: 1,
        writer: '운영자',
        title: '낙월해상풍력, 국내 공급망 기업과 함께 간다',
        images: ['1_0.jpeg'],
        content:
`[일렉트릭파워 박윤석 기자] 전남 영광에서 364MW 규모 낙월해상풍력 프로젝트를 추진하고 있는 명운산업개발이 국내 공급망 기업과 협력 채널 구축에 나섰다.

명운산업개발은 6월 27일 JW메리어트 호텔에서 낙월해상풍력사업에 참여하는 기업들과 프로젝트 협력 강화를 위한 협의체를 구성하고 발대식을 가졌다. 협의체 운영을 통해 국내 공급망 기업들과의 동반성장을 확대해 나간다는 계획이다.

이날 협의체 발대식에는 낙월해상풍력 개발사업과 관련해 기자재, 설계·시공, 엔지니어링, 금융, 인허가 등 프로젝트에 참여하는 국내 100여 업체 관계자들이 참석했다.

낙월해상풍력 프로젝트는 특수목적법인(SPC) 낙월블루하트가 전남 영광군 낙월면 안마도와 송이도 일원 공유수면에 364.8MW 규모 해상풍력단지를 건설하는 사업이다.

2017년 풍황 계측기 설치를 시작으로 2019년 1월 발전사업허가를 취득한 이래 2021년 5월 군작전성검토를 마쳤다. 2021년 7월 공유수면점사용 실시계획 승인과 2022년 11월 공사계획인가를 거쳐 올해 3월 육상부 착공에 들어갔다. 해상 공사는 오는 8월 착공 예정이다.

지난해 실시한 고정가격계약 경쟁입찰에 선정돼 올해 2월 남부발전과 REC 공급계약을 체결했다. 2026년 7월 상업운전을 목표하고 있는 가운데 핵심 기자재인 해상풍력터빈은 중국 골드윈드가 인수한 벤시스의 5.7MW 모델 64기가 설치될 예정이다.

발주금액 기준 국내 기업 70% 차지

낙월해상풍력은 국내 기업이 다수 참여하는 대규모 프로젝트란 점에서 해상풍력 분야로 사업 확장을 모색하고 있는 국내 기업들의 주목을 받고 있다. 민간기업이 추진하는 대규모 해상풍력 프로젝트 가운데 가장 빠른 속도를 보이고 있어 국내 공급망 기업의 시장 참여 기회를 가늠할 수 있는 지표로 꼽히고 있다.

명운산업개발에 따르면 풍력터빈 등 일부 품목을 제외하고 낙월해상풍력 개발은 약 70%의 국내 기업 참여로 추진된다. 이 같은 비중은 발주금액을 기준으로 한 것이라 향후 국내 기업들에게 돌아갈 경제적 이득 또한 상당할 것으로 기대된다.

명운산업개발이 국내 공급망 기업 참여를 적극 고려한 것은 해상풍력 프로젝트에 직접 참여해 경험과 기술을 축적할 수 있는 기회를 가져야 관련 시장에서 경쟁력을 발휘할 수 있다는 판단에서다.

우선 하부구조물 제작·공급에는 국내 대중소기업이 힘을 모았다. 모노파일 하부구조물 제작은 GS엔텍에서 담당한다. 모노파일 원자재에 해당하는 후판 공급은 포스코와 현대제철이 맡았다. 하부구조물 트랜지션피스(TP) 제작은 삼일씨엔에스, 화승에너지, 영진 등이 수행한다.

국내 대규모 해상풍력 프로젝트에 모노파일 하부구조물을 적용한건 낙월해상풍력이 최초다. 해상풍력 하부구조물 분야로 사업 보폭을 넓히고 있는 GS엔텍은 이번 모노파일 제작을 통해 해상풍력 시장에 처음 진출하는 기회를 잡았다.

해저케이블 분야에선 대한전선이 제품 제작을 맡고 해천이 시공을 담당한다. 대한전선은 낙월해상풍력 내부망 공급을 계기로 해저케이블 시장 확대를 위한 디딤돌을 마련하게 됐다.

GIS·변압기 등 전력기기 분야에도 국내 기업이 참여한다. LS일렉트릭, 일진전기, 영인기술, 영인에너지솔루션, 에디슨전기 등이 낙월해상풍력 전력기기 공급을 맡는다.

건설·시공 분야에는 EPC 업체인 호반산업과 삼해종합건설, 토성토건을 비롯해 다수의 협력업체들이 참여할 예정이다. 설계·엔지니어링는 한국종합기술, 드림엔지니어링, 성신ENC 등이 수행하기로 했다.

이 외에도 ▲한전KDN·영국전자(운영관리·모니터링) ▲현대해상·와이즈손해보험·MSI·로엘회계법인·김&장·지평(금융·회계·법무) 등이 낙월해상풍력 개발에 참여한다.

명운산업개발은 현재 낙월해상풍력 프로젝트 관련 해양조사와 설계업무를 마무리한 가운데 하부구조물·해저케이블·전력기기·시공을 위한 모든 기자재 발주를 마친 상태다. 내년까지 조 단위 자금을 집행함으로써 100여 개 국내 참여기업의 수익개선에도 긍정적인 영향을 미칠 전망이다.

김강학 명운산업개발 회장은 환영사를 통해 낙월해상풍력 개발과 관련해 투자와 협력을 이어온 참여기업들에게 감사의 뜻을 표했다.

김강학 회장은 “처음 가는 길이라 부침도 많았지만 상호 신뢰를 바탕으로 함께한 참여기업들에게 감사드린다”며 “이제 해상풍력 산업계 차원에서 실질적인 협력과 시너지 창출 기회를 맞고 있다”고 밝혔다.

이어 “낙월해상풍력 참여를 통한 트랙레코드 확보로 참여기업들의 기술개발 또한 이뤄질 것”이라며 “앞으로 협의체가 국가 에너지전환과 기후위기 대응에 일조할 수 있도록 적극적인 참여 부탁드린다”고 덧붙였다.

출처 : 일렉트릭파워(http://www.epj.co.kr)`
    }

    

]

export default boardData;