import { useEffect, useRef, useState } from 'react';
import './../App.css';
import serviceData from '../data/serviceData';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next'
import i18n from "i18next";
// react-slick
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import Slider from 'react-slick';



export default function Intro(){
    const navigate = useNavigate();
    const { t }  = useTranslation(['intro']) //i18n

    const [slideIndex, setSlideIndex] = useState(0);
    const [updateCount, setUpdateCount] = useState(0);
    let sliderRef = useRef();
    const settings = {
        dots: false,
        fade: true,
        infinite: true,
        speed: 500, // 사진이 바뀌고, speed 만큼 시간이 지나야 updateCount가 시작됨
        slidesToShow: 1,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3500,
        cssEase: "linear",
        afterChange: () => setUpdateCount(updateCount + 1),
        beforeChange: (next) => setSlideIndex(next)
    };

    // useEffect(() => {
    //     setTimeout(() => {
    //         sliderRef.current.style.transition = `width 0s linear 0s`;
    //         sliderRef.current.style.width = '0'; 
    //     }, 0);
    //     setTimeout(() => {
    //         sliderRef.current.style.transition = `width 2.5s ease-out 0s`;
    //         sliderRef.current.style.width = '100%';
    //     }, 100);
    // }, [updateCount]) // slideIndex는 두번째 페이지에서 전환이 안됨
    
    const modal = () => {
        alert('해상풍력 안전관리에 관한 일반 문의 또는 미디어 관련 문의는 다음 양식을 작성해서 아래의 주소로 이메일을 보내주시기 바랍니다.\n수신 이메일 : jylee@soouniverse.com 바랍니다.\n\n이름\n성\n이메일 주소\n회사명/소속\n문의내용')
    }


    return(
        <>
            <div className='intro'>
                <div className='intro-hero'>
                    <div className='intro-hero__txt'>
                        <div className='intro-hero__txt-con'>
                            <div className='intro-hero__txt-title'>
                                {t('intro:intro-hero__txt-title').split("\n").map((line)=>(<div>{line}</div>))}
                            </div>
                            <div className='intro-hero__txt-sub'>
                                {t('intro:intro-hero__txt-sub').split("\n").map((line)=>(<div>{line}</div>))}
                            </div>
                            <div className='intro-hero__btn-con'>
                                <a href='' onClick={()=>{modal()}}>
                                    <span> {t('intro:contact')}&nbsp;&nbsp;&#8594;</span>
                                </a>
                                <a href='/company'><span>{t('intro:more01')}&nbsp;&nbsp;+</span></a>
                            </div>
                        </div>
                    </div>
                    <div className='intro-hero__img-wrap'>
                        {/* <div className='progress-bar-wrap'> */}
                            {/* <div {...fadeInH1}></div> */}
                            {/* <div ref={sliderRef}></div> */}
                        {/* </div> */}
                        {/* <img src='/images/hero.jpg' decoding="async"/> */}
                        <Slider className='intro-hero__slick'
                        // ref={slider => {sliderRef = slider;}}
                        {...settings}>
                            <img src={`/images/hero.jpg`} decoding='async' />
                            <img src={`/images/hero02.jpg`} decoding='async' />
                            <img src={`/images/hero03.jpg`} decoding='async' />
                        </Slider>
                    </div>
                </div>

                <div className='intro-brand'>
                    <div className='intro-brand__title'>
                        {t('intro:intro-brand__txt-title').split("\n").map((line)=>(<div>{line}</div>))}
                    </div>
                    <div className='intro-brand__img-wrap'>
                        <img src='/images/brand.jpg' decoding="async"/>
                    </div>
                    <div className='intro-brand__txt-con'>
                        <div className='intro-brand__txt-item'>
                            <div>{t('intro:intro-brand__txt-item01-01').split("\n").map((line)=>(<div>{line}</div>))}</div>
                            <div>{t('intro:intro-brand__txt-item01-02').split("\n").map((line)=>(<div>{line}</div>))}</div>
                        </div>
                        <div className='intro-brand__txt-item'>
                            <div>{t('intro:intro-brand__txt-item02-01').split("\n").map((line)=>(<div>{line}</div>))}</div>
                            <div>{t('intro:intro-brand__txt-item02-02').split("\n").map((line)=>(<div>{line}</div>))}</div>
                        </div>
                    </div>
                    <div className='btn-more'>
                        <a href='/company'><span>{t('intro:more02')}&nbsp;&nbsp;+</span></a>
                    </div>
                </div>

                <div className='intro-service'>
                    <div className='intro-service__txt-con'>
                        <div className='intro-service__txt-title'>{t('intro:intro-service__txt-title').split("\n").map((line)=>(<div>{line}</div>))}</div>
                        <div className='intro-service__txt-sub'>{t('intro:intro-service__txt-sub').split("\n").map((line)=>(<div>{line}</div>))}</div>
                    </div>
                    <div className='section__txt-line'></div>
                    <div className='intro-service__grid-con'>
                            {
                                serviceData.map((a, i) => {
                                    return(
                                        <>
                                            <div className='intro-service__grid-item'>
                                                {/* <div className='intro-service__grid-item-icon'>{'0' + (i+1)}</div> */}
                                                <div className='intro-service__grid-item-icon'><img src={`/icons/${a[0]}`} decoding="async"/></div>
                                                <div className='intro-service__grid-item-title'>{t(`intro:intro-service__grid-item-title${i+1}`)}</div>
                                                <div className='intro-service__grid-item-sub'>{t(`intro:intro-service__grid-item-sub${i 
                                                + 1}`)}</div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                    </div>
                    <div className='btn-more'>
                        <a href='/ourservice'><span>더 보기&nbsp;&nbsp;+</span></a>
                    </div>
                </div>

                <div className='intro-menu'>
                    <div className='intro-menu__up-grid-con'>
                        <div onClick={() => {navigate('/company')}}>
                            <div className='intro-menu__bg-gray' style={{backgroundColor:'rgb(0,0,0,0.1)'}}></div>
                            <img src='/images/menu01.jpg' decoding="async"/>
                            <span>COMPANY</span>
                        </div>
                        <div onClick={() => {navigate('/ourservice')}}>
                            <div className='intro-menu__bg-gray' style={{backgroundColor:'rgb(0,0,0,0.1)'}}></div>
                            <img src='/images/menu02.jpg' decoding="async"/>
                            <span>SERVICE</span>
                        </div>
                        <div onClick={() => {navigate('/gallery')}}>
                            <div className='intro-menu__bg-gray' style={{backgroundColor:'rgb(0,0,0,0.1)'}}></div>
                            <img src='/images/menu03.jpg' decoding="async"/>
                            <span>GALLERY</span>
                        </div>
                    </div>
                    <div className='intro-menu__down-grid-con'>
                        <div onClick={() => {navigate('/news/list')}}>
                            <div className='intro-menu__bg-gray' style={{backgroundColor:'rgb(0,0,0,0.1)'}}></div>
                            <img src='/images/menu04.jpg' decoding="async"/>
                            <span>NEWS</span>
                        </div>
                        <div>
                            <img src='/images/menu05.jpg' decoding="async"/>
                        </div>
                    </div>
                </div>

                <div className='intro-contact'>
                    <div className='intro-contact__txt'>
                        <div className='intro-contact__txt-con'>
                            <div className='intro-contact__txt-title'>
                                {t('intro:intro-contact__txt-title').split("\n").map((line)=>(<div>{line}</div>))}
                            </div>
                            <div className='intro-contact__txt-sub'>
                                <li>&#10003;&nbsp;&nbsp;{t('intro:intro-contact__txt-sub01')}</li>
                                <li>&#10003;&nbsp;&nbsp;{t('intro:intro-contact__txt-sub02')}</li>
                                <li>&#10003;&nbsp;&nbsp;{t('intro:intro-contact__txt-sub03')}</li>
                            </div>
                            <div className='intro-contact__btn-con'>
                                <a href='' onClick={()=>{modal()}}><span>{t('intro:contact')}&nbsp;&nbsp;&#8594;</span></a>
                            </div>
                        </div>
                    </div>
                    <div className='intro-contact__img-wrap'>
                        <img src='/images/contact.jpg' decoding="async"/>
                    </div>
                </div>
            </div>
        </>
    )
}