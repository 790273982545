import './App.css';
import { Route, Routes } from 'react-router-dom';
import { useEffect, useState } from 'react';
import { Intro, Footer, Header,
        Introduction, OurService,
        Gallery,
        BoardList,
        License,
        BoardDetail
        } from './routes';


function App() {
  
  return (
    
    <div className="App">
        <Header />
        <Routes>
            <Route path="/" element={<Intro />} />
            <Route path="/license" element={<License />} />
            
            <Route path="/company" element={<Introduction />} />
            <Route path="/ourservice" element={<OurService />} />
            <Route path="/gallery" element={<Gallery />} />

            <Route path="/news">
              <Route path="list" element={ <BoardList /> } />
              <Route path="detail/:id" element={ <BoardDetail /> } />
            </Route>
            
        </Routes>
        <Footer />
    </div>
  );
}

export default App;
