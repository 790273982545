import { useEffect, useState } from 'react';
import './../../../App.css';
import serviceData from '../../../data/serviceData';
import { useTranslation } from 'react-i18next'
import i18n from "i18next";

export default function OurService(){
    const { t }  = useTranslation(['intro']) // i18n

    return(
        <>
            <div className='detail-page'>
                <div className='detail-page__banner-con'>
                    <div>SERVICE</div>
                    <img src='/images/menu_banner.jpg' decoding="async"/>
                </div>

                <div className='detail-page__con'>
                    <div className='detail-page__txt-line'></div>
                    <div className='detail-page__txt-con'>
                        <div className='detail-page__txt-title'>{t('service:header-title01').split("\n").map((line)=>(<div>{line}</div>))}</div>
                    </div>

                    <div className='ourservice__con'>
                        <img src="/images/menu01.jpg" className='img-it' />
                        <ul className='ourservice__box-con'>
                            <li className='ourservice__box-item'>
                                <div className='ourservice__box-txt01'>1.</div>
                                <div className='ourservice__box-txt02'>
                                    {t('service:box01-txt02').split("\n").map((line)=>(<div>{line}</div>))}</div>
                                <div className='ourservice__box-txt03'>
                                    {t('service:box01-txt03').split("\n").map((line)=>(<div>{line}</div>))}
                                </div>
                            </li>
                            <li className='ourservice__box-item'>
                                <div className='ourservice__box-txt01'>2.</div>
                                <div className='ourservice__box-txt02'>
                                    {t('service:box02-txt02').split("\n").map((line)=>(<div>{line}</div>))}</div>
                                <div className='ourservice__box-txt03'>
                                    {t('service:box02-txt03').split("\n").map((line)=>(<div>{line}</div>))}
                                </div>
                            </li>
                            <li className='ourservice__box-item'>
                                <div className='ourservice__box-txt01'>3.</div>
                                <div className='ourservice__box-txt02'>
                                    {t('service:box03-txt02').split("\n").map((line)=>(<div>{line}</div>))}</div>
                                <div className='ourservice__box-txt03'>
                                    {t('service:box03-txt03').split("\n").map((line)=>(<div>{line}</div>))}
                                </div>
                            </li>
                            <li className='ourservice__box-item'>
                                <div className='ourservice__box-txt01'>4.</div>
                                <div className='ourservice__box-txt02'>
                                    {t('service:box04-txt02').split("\n").map((line)=>(<div>{line}</div>))}</div>
                                <div className='ourservice__box-txt03'>
                                    {t('service:box04-txt03').split("\n").map((line)=>(<div>{line}</div>))}
                                </div>
                            </li>
                        </ul>
                    </div>

                    
                    <div className='detail-page__txt-line'></div>
                    <div className='detail-page__txt-con'>
                        <div className='detail-page__txt-title'>{t('service:header-title02').split("\n").map((line)=>(<div>{line}</div>))}</div>
                    </div>

                    <div className='intro-service__grid-con' style={{marginBottom: '100px'}}>
                            {
                                serviceData.map((a, i) => {
                                    return(
                                        <>
                                            <div className='intro-service__grid-item ourservice__grid-item'>
                                                {/* <div className='intro-service__grid-item-icon'>{'0' + (i+1)}</div> */}
                                                <div className='intro-service__grid-item-icon'><img src={`/icons/${a[0]}`} decoding="async"/></div>
                                                <div className='intro-service__grid-item-title'>{t(`intro:intro-service__grid-item-title${i+1}`)}</div>
                                                <div className='intro-service__grid-item-sub'>{t(`intro:intro-service__grid-item-sub${i 
                                                + 1}`)}</div>
                                            </div>
                                        </>
                                    )
                                })
                            }
                    </div>
                    
                </div>
            </div>
        </>
    )
}