import { useEffect, useState } from 'react';
import './../../../App.css';
import { useNavigate, useParams } from 'react-router-dom';
import data from '../../../data/boardData';

export default function BoardDetail(){
    const navigate = useNavigate();
    const {id} = useParams();

    const contentData = data[parseInt(id) - 1]; // data 숫자는 0부터 시작하기에 -1

    // [reg] string 내에 url에 자동 링크생성
    function autoLink(message: string) {
        if (message){            
            const Rexp =
            /(\b(https?|ftp|file):\/\/([-A-Z0-9+&@#%?=~_|!:,.;]*)([-A-Z0-9+&@#%?/=~_|!:,.;]*)[-A-Z0-9+&@#/%=~_|])/gi;
      
            return message.replace(
                Rexp,
                "<a href='$1' target='_blank'>$1</a>"
            );
        }
    }

    return(
        <>
            <div className='detail-page'>
                <div className='detail-page__banner-con'>
                    <div>NEWS</div>
                    <img src='/images/menu_banner.jpg' decoding="async"/>
                </div>

                <div className='detail-page__con'>
                    <div className='detail-page__txt-line'></div>
                    <div className='detail-page__txt-con'>
                        <div className='detail-page__txt-title'>뉴스 및 최신소식</div>
                    </div>

                    <div className='board-detail__board-name'>
                        <span onClick={() => {navigate('/news/list')}}>게시판 목록</span>
                    </div>
                    <div className='board-detail__header'>
                        <div className='board-detail__header-title'>
                            {contentData?.title}
                        </div>
                        <div className='board-detail__header-info'>
                            <ul>
                                <li>{contentData?.writer}</li>
                                <li>번호 {id}</li>
                            </ul>
                        </div>

                        <div className='board-detail__content-con'>
                            {/* textarea 내용의 엔터인식을 위해 <pre></pre>로 감싸기 */}
                            <div className='board-detail__content-item'>
                                {contentData.images.map((a,i)=>{return(
                                    <img src={`/images/board/${a}`} />
                                )})}
                                <pre dangerouslySetInnerHTML={{__html: autoLink( contentData?.content)}}>
                                </pre>
                            </div>
                        </div>
                    </div>  
                </div>
            </div>
        </>
    )
}